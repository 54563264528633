<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">VaultedByAli</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <i class="bx bx-menu"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link" aria-current="page" href="#passwords"><i class="bx bx-lock-alt"></i>
              <span class="sidebar-item-name">Passwords</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#notes"><i class="bx bx-book-bookmark"></i>
              <span class="sidebar-item-name">Notes</span></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container-fluid">
    <div class="wrapper">
      <div class="login-page">
        <div class="login-form">
          <form action="" class="form-login">
            <h1>Authorize</h1>
            <div class="input-box">
              <input id="inputPassword" type="password" ref="passwordInput" placeholder="Password" required
                form="novalidatedform" />
              <i class="bx bxs-lock-alt"></i>
            </div>
            <button @click="submitForm()" id="btnLogin" type="submit" class="btn">
              Login
            </button>
          </form>
        </div>
      </div>
      <div class="wrapper-right">
        <div class="tooltipMessage">
          <div class="alert alert-light" role="alert">
            <div class="alert-icon"><i class="bx bxs-check-circle"></i></div>
            <div class="alert-text">{{ alertText }}</div>
          </div>
          <span class="bottom"></span>
        </div>
        <div class="searchSection">
          <div class="search-accounts">
            <input class="search-box" type="text" v-model="searchText" placeholder="Search.." />
            <i class="bx bx-search-alt"></i>
          </div>
          <div class="dropdown dropdown-center">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Sort by
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a @click="sortBy(0)" class="dropdown-item" href="#">Name</a>
              </li>
              <li>
                <a @click="sortBy(1)" class="dropdown-item" href="#">Date added (desc)</a>
              </li>
              <li>
                <a @click="sortBy(2)" class="dropdown-item" href="#">Date added (asc)</a>
              </li>
            </ul>
          </div>
        </div>
        <button type="button" @click="addAccount()" class="btn btn-success btnAddAccount">
          Add account
        </button>
        <button type="button" @click="addAccount()" class="btn btn-success btnAddAccountMobile">
          <i class="bx bxs-user-plus"></i>
        </button>
        <div class="accounts">
          <div class="newAccount">
            <AccountItem :domain="''" :email="''" :username="''" :password="''" :id="'newAccount'" :key="'newAccount'"
              @update:data="updateAccount" @create:data="createAccount" @cancel="cancelCreate" @delete:id="deleteAccount"
              :isNewAccount="true" />
          </div>
          <AccountItem v-for="acc in accounts" :key="acc._id" :domain="acc.domain" :email="acc.email"
            :username="acc.username" :password="acc.password" :id="acc._id" @update:data="updateAccount"
            @create:data="createAccount" @cancel="cancelCreate" @delete:id="deleteAccount" @alert:message="showAlert"
            :isNewAccount="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountItem from "./components/AccountItem.vue";

export default {
  name: "App",
  components: {
    AccountItem,
  },
  data() {
    return {
      accounts: [],
      allAccounts: [],
      searchText: "",
      alertText: "This is an alert",
      sortMode: 1,
    };
  },
  watch: {
    searchText(newText) {
      this.showAccounts(newText);
    },
  },

  mounted() {
    $(".form-login").submit(function (e) {
      e.preventDefault();
    });

    this.initializeIfLoggedin();

    $(".search-accounts i").on("click", () => {
      this.searchText = "";
    });
    var input = document.getElementById("inputPassword");

    // Execute a function when the user presses a key on the keyboard
    input.addEventListener("keypress", function (event) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        document.getElementById("btnLogin").click();
      }
    });
    $(".nav-item").on("click", function () {
      $(".nav-item").removeClass("active"); // Remove the class from all links
      $(this).addClass("active"); // Add the class to the clicked link
    });
  },
  methods: {
    initializeIfLoggedin() {
      fetch("https://www.vaultedbyali.com/accounts", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            response.json().then((result) => {
              this.allAccounts = result;
              this.accounts = this.allAccounts;
              this.sortBy(this.sortMode);
              $(".login-page").animate({ width: 0 }, 1500);
              $(".wrapper-right").css("display", "flex");
              $(".dropdown").css("display", "block");
              $(".navbar-nav").css("transform", "translateY(0%)");
            });
          }
        })
        .catch();
    },
    submitForm() {
      const passwordInput = this.$refs.passwordInput;
      fetch("https://www.vaultedbyali.com/token", {
        method: "POST",
        body: JSON.stringify({
          password: passwordInput.value,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            response.json().then((result) => {
              localStorage.setItem("token", result.token);
              this.getAllAccounts();
              $(".login-page").animate({ width: 0 }, 1500);
              $(".wrapper-right").css("display", "flex");
              $(".dropdown").css("display", "block");
              $(".navbar-nav").css("transform", "translateY(0%)");
            });
          }
        })
        .catch((error) => console.log("error", error));
    },

    getAllAccounts() {
      fetch("https://www.vaultedbyali.com/accounts", {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            response.json().then((result) => {
              this.allAccounts = result;
              this.accounts = this.allAccounts;
              this.sortBy(this.sortMode);
            });
          }
        })
        .catch((error) => { console.log("error", error); });
    },
    updateAccount(data) {
      fetch("https://www.vaultedbyali.com/accounts/" + data.id, {
        method: "PUT",
        body: JSON.stringify({
          username: data.username,
          email: data.email,
          password: data.password,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            response.json().then((result) => {
              const updatedAccountIndex = this.accounts.findIndex(
                (acc) => acc._id == result._id
              );
              if (updatedAccountIndex !== -1) {
                this.accounts[updatedAccountIndex].password = result.password;
                this.accounts[updatedAccountIndex].email = result.email;
                this.accounts[updatedAccountIndex].username = result.username;
              }
              const updatedAccountIndex2 = this.allAccounts.findIndex(
                (acc) => acc._id == result._id
              );
              if (updatedAccountIndex2 !== -1) {
                this.allAccounts[updatedAccountIndex2].password =
                  result.password;
                this.allAccounts[updatedAccountIndex2].email = result.email;
                this.allAccounts[updatedAccountIndex2].username =
                  result.username;
                this.showAlert("Account updated.");
              }
            });
          } else {
            console.log(response);
          }
        })
        .catch((error) => console.log("error", error));
    },
    createAccount(data) {
      fetch("https://www.vaultedbyali.com/accounts/", {
        method: "POST",
        body: JSON.stringify({
          domain: data.domain,
          username: data.username,
          email: data.email,
          password: data.password,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            response.json().then((result) => {
              this.getAllAccounts();
              this.showAlert("Account created.");
            });
          } else {
            console.log(response);
          }
        })
        .catch((error) => console.log("error", error));
    },
    cancelCreate() {
      $(".newAccount").css("display", "none");
    },
    deleteAccount(id) {
      fetch("https://www.vaultedbyali.com/accounts/" + id, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            response.json().then((result) => {
              const index = this.accounts.findIndex((acc) => acc._id == id);
              if (index !== -1) {
                // Remove the account object from the accounts array
                this.accounts.splice(index, 1);
              }
              const index2 = this.allAccounts.findIndex((acc) => acc._id == id);
              if (index2 !== -1) {
                // Remove the account object from the accounts array
                this.allAccounts.splice(index2, 1);
              }
              this.showAlert("Account deleted.");
            });
          } else {
            console.log(response);
          }
        })
        .catch((error) => console.log("error", error));
    },
    showAccounts(newText) {
      if (newText) {
        $(".search-accounts i").removeClass("bx-search-alt");
        $(".search-accounts i").addClass("bx-undo");
      } else {
        $(".search-accounts i").removeClass("bx-undo");
        $(".search-accounts i").addClass("bx-search-alt");
      }

      this.accounts = this.allAccounts.filter((item) =>
        item.domain.toLowerCase().includes(newText.toLowerCase())
      );
    },
    addAccount() {
      $(".newAccount").css("display", "flex");
    },
    parseDateString(dateString) {
      const initialDate = dateString.split(" ");
      // Split the input string based on either "/" or "-"
      const dateParts = initialDate[0].split(/[/|-]/);

      if (dateParts.length === 3) {
        var day = parseInt(dateParts[0], 10);
        var month = parseInt(dateParts[1], 10) - 1; // Month is zero-based
        var year = parseInt(dateParts[2], 10);
        if (month > 12) {
          var tempMonth = month;
          month = day - 1;
          day = tempMonth + 1;
        }
        // Check if the parsed values are valid
        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
          var finalDate = new Date(year, month, day);
          return finalDate;
        }
      }

      // If parsing fails or the format is incorrect, return null or handle the error as needed
      return null;
    },
    showAlert(message) {
      this.alertText = message;
      $(".tooltipMessage").css("right", "50%");
      $(".tooltipMessage").css("opacity", "100%");
      $(".tooltipMessage .bottom").css("transform", "scaleX(1)");
      this.sleep(2500).then(() => {
        $(".tooltipMessage").css("right", "-50%");
        $(".tooltipMessage").css("opacity", "0%");
        $(".tooltipMessage .bottom").css("transform", "scaleX(0)");
      });
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    sortBy(mode) {
      this.sortMode = mode;
      switch (mode) {
        case 0:
          this.accounts = this.accounts.sort((a, b) => {
            return a.domain.toLowerCase().localeCompare(b.domain.toLowerCase());
          });
          break;
        case 1:
          this.accounts = this.accounts.sort((a, b) => {
            return (
              this.parseDateString(b.dateadded) -
              this.parseDateString(a.dateadded)
            );
          });
          break;
        case 2:
          this.accounts = this.accounts.sort((a, b) => {
            return (
              this.parseDateString(a.dateadded) -
              this.parseDateString(b.dateadded)
            );
          });
          break;
        default:
          console.log(`Sorry, we are out of ${expr}.`);
      }
    },
  },
};
</script>

<style>
.searchSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  background: black !important;
  border-bottom: 2px solid rgba(4, 191, 173, 0.7);
  /* background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 7) 0%,
    rgba(63, 124, 133, 0.7) 100%
  ); */
}

.navbar-nav {
  margin-left: 1rem;
}

.navbar a {
  color: white !important;
}

.navbar-nav {
  transform: translateY(-150%);
  transition: all .8s;
}

.navbar-toggler i {
  color: white !important;
  font-size: 3rem;
}

.navbar-toggler i:hover {
  color: white !important;
  font-size: 3rem;
  transform: translateY(5%);
  transition: all 0.2s;
}

.nav-item {
  text-align: center;
  margin-right: 1em;
}

.nav-item:hover {
  border-bottom: 2px solid #fff;
  transform: translateY(-5%);
  transition: all 0.2s;
}

.nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
}

.navbar-nav .active {
  border-bottom: 2px solid #fff;
}

.navbar-brand {
  color: white;
  font-family: "Lobster", cursive;
  font-size: 2rem;
}

.dropdown {
  margin: 1em;
  display: none;
}

.dropdown .btn {
  background: #fff;
  border: none;
  outline: none;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: #fff;
  background: rgba(18, 32, 36, 1);
  transition: all 0.2s;
}

.dropdown .btn:hover {
  background: rgba(18, 32, 36, 0.5);
  transform: translate(-2%, 2%);
}

.dropdown-menu {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
}

.dropdown-menu a {
  color: #fff;
}

.dropdown-menu a:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
  font-weight: 600;
}

.tooltipMessage {
  position: absolute;
  bottom: 2rem;
  z-index: 12;
  right: -50%;
  transform: translate(50%, 0);
  transition: all 1.5s;
  overflow: hidden;
}

.tooltipMessage .bottom {
  position: absolute;
  display: block;
  background: #000;
  transition: all 1s linear;
  bottom: 0px;
  left: 0;
  transform-origin: top left;
  width: 100%;
  height: 5px;
  transform: scaleX(0);
  transition-delay: 1.5s;
  background: rgba(4, 191, 173, 1);
}

html,
body {
  background-color: #151515;
  height: 100%;
  font-family: "Titillium Web", sans-serif;
  scroll-behavior: smooth;
  width: 100%;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("../images/wp3.jpg") no-repeat;
  background-position: center;
  height: 100%;
}

.newAccount {
  display: none;
}

.container-fluid {
  padding: 0;
  height: 90%;
  overflow: hidden;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  max-height: 100%;
  min-height: 100%;
}

.wrapper-left {
  text-align: center;
  background-color: rgba(23, 32, 38, 0.8);
}

.wrapper-right {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  display: none;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.accounts {
  margin-top: 1em;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  overflow: scroll;
}

.btnAddAccount {
  position: absolute;
  top: 1em;
  right: 1em;
  background: rgba(4, 191, 173, 1);
  transition: all 0.2s;
}

.btnAddAccountMobile {
  position: absolute;
  top: 1em;
  right: 1em;
  background: rgba(4, 191, 173, 1);
  display: none;
}

.btnAddAccount:hover,
.btnAddAccountMobile:hover {
  position: absolute;
  top: 1.1em;
  right: 1.1em;
  background: rgba(4, 191, 173, 0.2);
}

.alert {
  background: rgba(0, 0, 0, 0.5);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 2px solid rgba(255, 255, 255, 0.4);
  color: white;
}

.alert-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert i {
  font-size: 2rem;
  color: rgba(4, 191, 173, 1);
}

.login-page {
  width: 100%;
  height: 90%;
  position: absolute;
  z-index: 9;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../images/wp3.jpg") no-repeat;
  background-position: center;
  overflow: hidden;
}

.login-form {
  border-radius: 1em;
  padding: 2em 2.5em;
}

.login-form {
  width: 400px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2em);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  color: #fff;
}

.login-form h1 {
  font-size: 3rem;
  text-align: center;
}

.search-accounts {
  position: relative;
}

.search-accounts i {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: white;
}

.bx-undo {
  transition: all 0.5s;
}

.bx-undo:hover {
  right: 1.2rem;
}

.search-box {
  height: 3em;
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 4rem;
  font-size: 1rem;
  color: #fff;
  padding: 1rem 2rem 1rem 1rem;
}

.search-box::placeholder {
  color: #fff;
}

.login-form .input-box {
  width: 100%;
  height: 3rem;
  margin: 2rem 0;
  position: relative;
}

.login-form input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 4rem;
  font-size: 1rem;
  color: #fff;
  padding: 1rem 2rem 1rem 1rem;
}

.login-form input::placeholder {
  color: #fff;
}

.input-box i {
  position: absolute;
  right: 1rem;
  transform: translateY(50%);
  font-size: 1.5rem;
}

.login-form .btn {
  width: 100%;
  height: 3rem;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 4rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 1.5em;
  color: #333;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
}

@media only screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
  }

  .btnAddAccount {
    display: none;
  }

  .btnAddAccountMobile {
    display: block;
  }
}

@media only screen and (max-width: 500px) {
  .searchSection {
    flex-direction: column-reverse;
  }

  .accounts {
    margin-top: 0;
  }
}
</style>
