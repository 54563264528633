<template>
  <div class="card" :class="id">
    <div class="card-body">
      <div @click="openCard()" class="card-open-header">
        <div class="card-icon">
          <img :src="imageUrl" alt="Domain logo" />
        </div>
        <div class="card-info">
          <div class="info-domain">{{ domain }}</div>
          <div class="info-user">{{ email }}</div>
        </div>
      </div>
      <div class="card-open-linebr">
        <hr />
      </div>
      <div class="card-open-info">
        <div class="card-open-info-single card-open-domain" style="display:none">
          Domain
          <div class="input-box domain">
            <input type="text" required readonly form="novalidatedform" />
            <i class="bx bxs-envelope"></i>
          </div>
        </div>
        <div class="card-open-info-single">
          Email
          <div @click="copyEmail()" class="input-box email">
            <input type="text" :value="email" required readonly form="novalidatedform" />
            <i class="bx bxs-envelope"></i>
          </div>
        </div>
        <div class="card-open-info-single">
          Username
          <div @click="copyUsername()" class="input-box username">
            <input type="text" :value="username" required readonly form="novalidatedform" />
            <i class="bx bxs-user"></i>
          </div>
        </div>
        <div class="card-open-info-single">
          Password
          <div class="passwordSingle">
            <div @click="copyPassword()" class="input-box password">
              <input type="text" :value="password" required readonly form="novalidatedform" />
              <i class="bx bxs-lock-alt"></i>
            </div>
            <i @click="generateNewPassword()" class='bx bx-refresh'></i>
          </div>
        </div>
      </div>
    </div>
    <div class="separator-menu"></div>
    <div class="card-menu">
      <button @click="enterEditMode()" class="modify">
        <i class="bx bx-customize" style="color: #ffffff"></i>
      </button>
      <div class="separator-buttons"></div>
      <button @click="changeOrDelete()" class="delete">
        <i class="bx bx-x" style="color: #ffffff"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountItem",
  props: {
    domain: String,
    email: String,
    username: String,
    id: String,
    password: String,
    isNewAccount: Boolean,
  },
  data() {
    return {
      imageUrl:
        "https://cdn.discordapp.com/attachments/715508865034354740/1155976657342046238/nologo.png",
      isOpen: false,
      editMode: false,
    };
  },
  methods: {
    fetchLogo(domain) {
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: "Bearer Oa5y5C7dLNJ/FZVthR6DBEOiYKWMspIe19XKAM9FFt8=",
        },
      };
      fetch("https://api.brandfetch.io/v2/search/" + domain, options)
        .then((response) => response.json())
        .then((response) => {
          if (Array.isArray(response) && response.length > 0) {
            if (response[0].icon) {
              this.imageUrl = response[0].icon;
            } else {
              this.imageUrl =
                "https://cdn.discordapp.com/attachments/715508865034354740/1155976657342046238/nologo.png";
            }
          } else {
            this.imageUrl =
              "https://cdn.discordapp.com/attachments/715508865034354740/1155976657342046238/nologo.png";
          }
        })
        .catch((err) => console.error(err));
    },
    generateNewPassword() {
      var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var genPassword = "";
      for (var i = 0; i <= 20; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        genPassword += chars.substring(randomNumber, randomNumber + 1);
      }
      $("." + this.id + " .password input").val(genPassword);
    },
    openCard() {
      if (this.isOpen) {
        this.editMode = false;
        $("." + this.id + " .modify").css(
          "background-color",
          "rgba(255, 255, 255, 0.2)"
        );
        $("." + this.id + " .delete").css(
          "background-color",
          "rgba(255, 95, 93, 0.7)"
        );

        $("." + this.id + " .delete i").removeClass("bx-save");
        $("." + this.id + " .delete i").addClass("bx-x");
        $("." + this.id + " input").attr("readonly", true);
        $("." + this.id + " .card-open-info").animate(
          { "max-height": "0px" },
          { duration: 400 }
        );
        $("." + this.id + " .card-open-linebr").animate(
          { width: "0px" },
          { duration: 400 }
        );
        $("." + this.id + " .card-open-linebr").css("display", "none");
        $("." + this.id + " .card-open-info").css("display", "none");
        $("." + this.id + " input").attr("readonly", true);
        $("." + this.id + " .bx-refresh").css("display", "none");
        this.isOpen = false;

      } else {
        $("." + this.id + " .card-open-info").animate(
          { "max-height": "100%" },
          { duration: 400 }
        );
        $("." + this.id + " .card-open-linebr").css("display", "block");
        $("." + this.id + " .card-open-linebr").animate(
          { width: "100%" },
          { duration: 400 }
        );
        $("." + this.id + " .card-open-info").css("display", "flex");
        $("." + this.id + " .email input").val(this.email);
        $("." + this.id + " .username input").val(this.username);
        $("." + this.id + " .password input").val(this.password);
        this.isOpen = true;
      }
    },
    enterEditMode() {
      if (this.isNewAccount) {
        $("." + this.id + " .email input").val("");
        $("." + this.id + " .username input").val("");
        $("." + this.id + " .password input").val("");
        this.$emit("cancel");
      } else {
        if (this.editMode) {
          this.editMode = false;
          $("." + this.id + " .modify").css(
            "background-color",
            "rgba(255, 255, 255, 0.2)"
          );
          $("." + this.id + " .delete").css(
            "background-color",
            "rgba(255, 95, 93, 0.7)"
          );

          $("." + this.id + " .delete i").removeClass("bx-save");
          $("." + this.id + " .delete i").addClass("bx-x");
          $("." + this.id + " input").attr("readonly", true);
          $("." + this.id + " .email input").val(this.email);
          $("." + this.id + " .username input").val(this.username);
          $("." + this.id + " .password input").val(this.password);
          $("." + this.id + " .bx-refresh").css("display", "none");
        } else {
          this.editMode = true;
          if (this.isOpen == false) {
            this.openCard();
          }
          $("." + this.id + " input").attr("readonly", false);
          $("." + this.id + " .modify").css(
            "background-color",
            "rgba(255, 95, 93, 0.7)"
          );
          $("." + this.id + " .delete").css(
            "background-color",
            "rgba(2, 166, 118, 0.7)"
          );
          $("." + this.id + " .delete i").removeClass("bx-x");
          $("." + this.id + " .delete i").addClass("bx-save");
          $("." + this.id + " .bx-refresh").css("display", "block");
        }
      }
    },
    changeOrDelete() {
      if (this.isNewAccount) {
        var data = {
          domain: $("." + this.id + " .domain input").val(),
          password: $("." + this.id + " .password input").val(),
          username: $("." + this.id + " .username input").val(),
          email: $("." + this.id + " .email input").val(),
        };
        if (data.password && (data.email || data.username) && data.domain) {
          this.$emit("create:data", data);
          this.$emit("cancel");
          $("." + this.id + " .email input").val("");
          $("." + this.id + " .username input").val("");
          $("." + this.id + " .password input").val("");
        } else {
          console.log("Required: \nDomain\nEmail or username\nPassword");
        }
      } else {
        if (this.editMode) {
          var data = {
            id: this.id,
            password: $("." + this.id + " .password input").val(),
            username: $("." + this.id + " .username input").val(),
            email: $("." + this.id + " .email input").val(),
          };
          if (data.password && (data.email || data.username)) {
            this.$emit("update:data", data);
          } else {
            console.log("Required: \nDomain\nEmail or username\nPassword");
          }
        } else {
          this.$emit("delete:id", this.id);
        }
      }
    },
    setNewAccount() {
      $("." + this.id + " .modify").css(
        "background-color",
        "rgba(255, 95, 93, 0.7)"
      );
      $("." + this.id + " .delete").css(
        "background-color",
        "rgba(2, 166, 118, 0.7)"
      );

      $("." + this.id + " .modify i").removeClass("bx-save");
      $("." + this.id + " .modify i").addClass("bx-x-circle");
      $("." + this.id + " .delete i").removeClass("bx-x");
      $("." + this.id + " .delete i").addClass("bx-save");
      $("." + this.id + " input").attr("readonly", false);
      $("." + this.id + " .card-open-domain").css("display", "block");
      $("." + this.id + " .card-open-header").css("height", "0").css("overflow", "hidden");
      $("." + this.id + " .card-open-linebr").css("display", "block");


    },
    copyUsername() {
      if (!this.editMode) {
        var username = $("." + this.id + " .username input").val();
        navigator.clipboard.writeText(username);
        this.$emit("alert:message", "Username copied.");
      }
    },
    copyPassword() {
      if (!this.editMode) {
        var password = $("." + this.id + " .password input").val();
        navigator.clipboard.writeText(password);
        this.$emit("alert:message", "Password copied.");
      }
    },
    copyEmail() {
      if (!this.editMode) {
        var email = $("." + this.id + " .email input").val();
        navigator.clipboard.writeText(email);
        this.$emit("alert:message", "Email copied.");
      }
    },
  },
  mounted() {
    if (this.isNewAccount) {
      this.openCard();
      this.setNewAccount();
    } else {
      this.fetchLogo(this.domain);
    }
  },
};
</script>

<style scoped>
.separator-menu {
  background-color: rgba(0, 0, 0, 0);
  width: 3px;
}

.separator-buttons {
  background-color: rgba(0, 0, 0, 0);
  height: 3px;
}

.modify {
  background-color: rgba(116, 126, 126, 0.7);
  border: none;
  flex: 1;
  border-top-right-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.2s;
}

.modify:hover {
  background-color: rgba(116, 126, 126, 0.4);
  margin-left: 0.1em;
  margin-right: -0.1em;
}

.delete:hover {
  background-color: rgba(255, 95, 93, 0.6);
  margin-left: 0.1em;
  margin-right: -0.1em;
}

.delete {
  background-color: rgba(255, 95, 93, 0.7);
  flex: 1;
  border: none;
  border-bottom-right-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.2s;
}

.card {
  margin: 0.2rem;
  display: flex;
  flex-direction: row;
  border: none;
  background: transparent;
}

.card-body:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2%);
}

.card-body {
  transition: all 0.2s;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(12.5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  text-overflow: clip;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-open-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.card-open-linebr {
  margin-top: 0.5rem;
  display: none;
  width: 0;
}

.card-open-info {
  max-height: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 80%;
  margin-top: 0.5rem;
  overflow: hidden;
}

.card-open-info-single {
  display: block;
  text-align: center;
  font-size: 0.8em;
  font-weight: 500;
}

.card-open-info-single .input-box {
  width: 100%;
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
}

.card-open-info-single input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 4rem;
  font-size: 0.8rem;
  color: #fff;
  padding: 1rem 2rem 1rem 2.5rem;
}

.card-open-info-single input::placeholder {
  color: #fff;
}

.passwordSingle {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.bx-refresh {
  font-size: 1.5rem;
  transform: translateY(29%);
  margin-left: .2rem;
  transition: all .3s;
  display:none;
}

.bx-refresh:hover {
  transform: translateY(26%);
  color: rgba(4, 191, 173, 1);
  cursor: pointer;
}

.input-box i {
  position: absolute;
  left: 1rem;
  top: 55%;
  transform: translateY(-50%);
  font-size: 1rem;
  width: 1rem;
}

.card-info {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 15em;
  min-width: 15em;
}

.card-menu {
  display: flex;
  flex-direction: column;
}

.card-icon img {
  max-width: 2.5rem;
  max-height: auto;
  min-height: 2.5rem;
  min-width: 2.5rem;
}

.card-icon {
  margin-right: 1em;
}

@media only screen and (max-width: 600px) {
  .card-info {
    min-width: 14rem;
  }
}

@media only screen and (max-width: 500px) {}

.info-user {
  font-size: 70%;
}</style>
